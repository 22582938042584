import { configureStore } from "@reduxjs/toolkit";
import sidebarShow from "./SLice/responsive";
import question from "./SLice/Question";
import keys from "./SLice/Keys";
import employee from "./SLice/Employee";
import Modal from "./views/Modal";

const rootReducer = {
  sidebarShow: sidebarShow,
  employee: employee,
  modal: Modal,
  question: question,
  keys,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
