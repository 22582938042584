import moment from "moment";
import axiosClient from "./axiosClient";
// const HOSTNAME = process.env.REACT_APP_HOST_NAME;
// const HOSTNAME = "https://server.stepsy.app:4001/api/v1";
const HOSTNAME = process.env.REACT_APP_API;

const fields = 'fields=["$all"]';
const table = "user";

const memberApi = {
  getAll: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&filter=${JSON.stringify(
      filter
    )}&limit=${limit}&paranoid=false&order=[["created_at", "desc"]]`;
    console.log(url);
    return axiosClient.get(url);
  },

  getVIPList: (page, limit) => {
    const now = moment().valueOf();
    const url = `${HOSTNAME}/${table}?${fields}&filter={"$or":[{"exp_purchase_time": {"$gte":"${now}"}},{"type": "ENTERPRISE"}] }&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  getVIPListCondition: (page, limit, condition, text) => {
    const now = moment().valueOf();
    let url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;

    if (condition === "learning_goal_survey") {
      url += `&filter={"$and":[{"exp_purchase_time":{"$gte":"${now}"}}, {"${condition}": {"$contains":["${text}"]} }]}`;
    } else if (
      condition === "age_group" ||
      condition === "login_type" ||
      condition === "level"
    ) {
      url += `&filter={"$and":[{"exp_purchase_time":{"$gte":"${now}"}}, {"${condition}": "${text}"}]}`;
    } else {
      url += `&filter={"$and":[{"exp_purchase_time":{"$gte":"${now}"}}, {"${condition}": {"$iLike":"%25${text}%25"} }]}`;
    }
    return axiosClient.get(url);
  },

  getFreeList: (page, limit) => {
    const now = moment().valueOf();
    const url = `${HOSTNAME}/${table}?${fields}&filter={"exp_purchase_time":{"$or":[{"$lte":"${now}"}, {"$eq": null }]}}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  getFreeListCondition: (page, limit, condition, text) => {
    const now = moment().valueOf();
    let url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    if (condition === "learning_goal_survey") {
      url += `&filter={"$and":[{"exp_purchase_time":{"$or":[{"$lte":"${now}"}, {"$eq": null }]}}, {"${condition}": {"$contains":["${text}"]} }]}`;
    } else if (
      condition === "age_group" ||
      condition === "login_type" ||
      condition === "level"
    ) {
      url += `&filter={"$and":[{"exp_purchase_time":{"$or":[{"$lte":"${now}"}, {"$eq": null }]}}, {"${condition}": "${text}"}]}`;
    } else {
      url += `&filter={"$and":[{"exp_purchase_time":{"$or":[{"$lte":"${now}"}, {"$eq": null }]}}, {"${condition}": {"$iLike":"%25${text}%25"} }]}`;
    }
    return axiosClient.get(url);
  },

  filter: (userType, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter={"type_member": "${userType}"}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  filterCondition: (text, conditions, page, limit) => {
    let url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    if (conditions !== "age_group") {
      url += `&filter={"${conditions}": {"$iLike":"%25${text}%25"}}`;
    } else {
      url += `&filter={"${conditions}": "${text}"}`;
    }

    return axiosClient.get(url);
  },
  filterConditionPurpose: (text, conditions, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter={"${conditions}": {"$contains":["${text}"]}}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  filterAll: (text, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"$or": [{"username":{"$iLike":"%25${text}%25"}},{"nickname":{"$iLike":"%25${text}%25"}},  {"login_type":{"$iLike":"%25${text}%25"}}, {"occupation":"${text}"}]}`;
    return axiosClient.get(url);
  },
  filterAllVip: (page, limit, text) => {
    const now = moment().valueOf();
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"$and": [  {"$or": [{"username":{"$iLike":"%25${text}%25"}},{"nickname":{"$iLike":"%25${text}%25"}},  {"login_type":{"$iLike":"%25${text}%25"}}, {"occupation":"${text}"}]} , {"exp_purchase_time": {"$gte":"${now}"}}]}`;
    return axiosClient.get(url);
  },
  filterAllFree: (page, limit, text) => {
    const now = moment().valueOf();
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"$and": [  {"$or": [{"username":{"$iLike":"%25${text}%25"}},{"nickname":{"$iLike":"%25${text}%25"}},  {"login_type":{"$iLike":"%25${text}%25"}}, {"occupation":"${text}"}]} , {"exp_purchase_time":{"$or":[{"$lte":"${now}"}, {"$eq": null }] }}  ]}`;
    return axiosClient.get(url);
  },
  filterOccupation: (text, conditions, page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter={"${conditions}": "${text}"}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
    return axiosClient.get(url);
  },
  getList: () => {
    const url = `${HOSTNAME}/${table}?${fields}&limit=999`;
    return axiosClient.get(url);
  },

  findOne: (id, token) => {
    const url = `${HOSTNAME}/${table}/${id}?fields=["$all"]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addUser: (body, token) => {
    const url = `${HOSTNAME}/${table}`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  register: (body, token) => {
    const url = `${HOSTNAME}/user`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  editUser: (body, id, token) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteUser: (id, token) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  uploadFile: (body, token) => {
    const url = `${HOSTNAME}/image/upload/`;
    return axiosClient.post(url, body, {
      headers: {
        "Content-Type": "image/png",
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default memberApi;
