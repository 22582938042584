import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
// const HOSTNAME = "https://server.stepsy.app:4001/api/v1";
const HOSTNAME = process.env.REACT_APP_API;

const fields = 'fields=["$all",{"question":["$all"]}]';
const table = "question_web";

const questionWebApi = {
  getAll: (page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["order","desc"]]`;
    return axiosClient.get(url);
  },

  countQuestion: (page, limit) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"is_picked":true}&order=[["order","desc"]]`;
    return axiosClient.get(url);
  },

  // getQuestion: (number, page, limit) => {
  //     const url = `${HOSTNAME}/${table}/get_question?${fields}&filter={"number":${number}}&order=[["order","desc"]]`;
  //     return axiosClient.get(url);
  // },
  getQuestion: (number) => {
    const url = `${HOSTNAME}/${table}/get_question?filter={"number":${number}}&fields=["$all", {"question" : ["$all"]}]&order=[["order", "asc"]]`;
    return axiosClient.get(url);
  },

  getId: (id) => {
    const url = `${HOSTNAME}/${table}?fields=["$all",{"question":["$all"]}]&filter={"lesson_id":"${id}"}&order=[["order","desc"]]`;
    return axiosClient.get(url);
  },

  findOne: (id) => {
    const url = `${HOSTNAME}/${table}/${id}?fields=["$all",{"question" : ["$all"]}]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  filterCorrect: (page, limit, condition, text) => {
    const url = `${HOSTNAME}/${table}/?fields=["$all",{"question" : ["$all"]}]&page=${page}&limit=${limit}&filter={"${condition}":"${text}"}&order=[["order","desc"]]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  filter: (page, limit, condition, text) => {
    const url = `${HOSTNAME}/${table}/?fields=["$all",{"question" : ["$all"]}]&page=${page}&limit=${limit}&filter={"${condition}":{"$iLike":"%25${text}%25"}}&order=[["updated_at","desc"]]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  filterAll: (page, limit, text) => {
    const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&filter={"$or": [{"title":{"$iLike":"%25${text}%25"}},  {"type":{"$iLike":"%25${text}%25"}}]}`;
    return axiosClient.get(url);
  },

  add: (body) => {
    const url = `${HOSTNAME}/${table}`;
    return axiosClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  update: (body, id) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  delete: (id) => {
    const url = `${HOSTNAME}/${table}/${id}`;
    return axiosClient.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  uploadFile: (body, token) => {
    const url = `${HOSTNAME}/image/upload/`;
    return axiosClient.post(url, body, {
      headers: {
        "Content-Type": "image/png",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  post: (body) => {
    const url = `${HOSTNAME}/auth/employee_login`;
    return axiosClient.post(url, body);
  },

  filterByQuestion: (page, limit, condition = "title", text) => {
    const url = `${HOSTNAME}/${table}/get_list_by_question?fields=["$all",{"question" : ["$all"]}]&page=${page}&limit=${limit}&filter={"${condition}":"${text}"}&order=[["order","desc"]]`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default questionWebApi;
